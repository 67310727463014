<template>
  <div>
    <div v-if="awardingBodiesLoading" class="py-16">
      <Loading title="Loading Awarding Bodies" :loading="awardingBodies"/>
    </div>

    <div v-else>
      <div class="pp-subtitle-h1 mt-10">
        Our courses are approved by leading UK awarding bodies
      </div>

      <div class="pp-hr"></div>

      <div class="mb-4">
        Many of our qualifications are nationally recognised. We work with a variety of awarding organisations to ensure that our qualifications are delivered to a high standard for all our clients.
      </div>

      <v-row class="mt-3 rounded-xl">
        <v-card
            v-for="(awardingBody, i) in awardingBodies"
            :key="i"
            flat
            class="whte ma-md-5 ma-2"
        >
          <picture v-if="awardingBody.image">
            <img
                :src="awardingBody.image.url"
                :alt="awardingBody.image.alt"
                height="80"
                width="80"
                class="contain"
            />
          </picture>
        </v-card>
      </v-row>
    </div>
  </div>
</template>

<script>
import Loading from "@/components/common/Loading";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "AwardingBodyLogosDisplay",
  components: {Loading},
  computed: {
    ...mapState(['awardingBodies', 'awardingBodiesLoading']),
    ...mapGetters(['awardingBodies', 'awardingBodiesLoading']),
  },
  methods: {
    ...mapActions(['getAwardingBodies']),
  },
  beforeMount() {
    this.getAwardingBodies({ tags: "awarding_body_logo_display" });
  },
};
</script>
