<template>
  <div>
    <v-container fluid class="pa-0">
      <UserBanner
          :background="content.background"
          :banner="content.banner"
          :backgroundlogo="content.backgroundlogo"
          :logo="content.logo"
          :image="content.image"
          :to="content.to"
          :learnmore="content.learnmore"
          :contentType="content.type"
      />

      <v-container
          class="px-xl-16"
          fluid>
        <v-container fluid class="px-xl-16">
          <UserBody
              :content="content"
          />
        </v-container>
      </v-container>
    </v-container>
  </div>
</template>

<script>
import UserBanner from "@/components/userPages/UserBanner";
import UserBody from "@/components/userPages/UserBody";

export default {
  name: "UserPageContent",
  components: {UserBody, UserBanner},
  props: {
    content: {
      type: Object,
    },
  },
};
</script>
