<template>
  <div class="hidden-xs-only">
    <div class="grey darken-3 rounded-xl">
      <v-row
          no-gutters
          class="pl-xl-16 py-1"
      >
        <v-col
            v-for="(step, i) in content"
            :key="i"
            cols="i === 2 ? 6 : undefined"
        >
          <v-list-item>
            <v-list-item-avatar size="50" tile>
              <v-img
                  :src="step.icon"
                  :alt="step.alt"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <div class="accent--text">
                {{ step.text }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "UserSteps",
  computed: {
    ...mapGetters(['userStepsIndividual', 'userStepsBusiness']),
  },
  props: {
    content: {
      type: Array,
    },
  },
};
</script>
