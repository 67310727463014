<template>
  <v-container>
    <div v-for="(content, i) in content"
         :key="i">
      <v-row class="pa-3">
        <div class="rounded-xl text-subtitle-1">
          <v-list-item>
            <v-list-item-avatar size="50" tile>
              <img
                  :src="content.logo"
                  alt="LearningPlus Logo"
                  height="35"
                  width="35"
                  class="contain"
              />
            </v-list-item-avatar>
            <v-list-item-content>
              <div>
                {{ content.text }}
              </div>
            </v-list-item-content>
          </v-list-item>
        </div>
      </v-row>
    </div>
  </v-container>
</template>

<script>
import {mapGetters} from "vuex";

export default {
  name: "AboutUser",
  computed: {
    ...mapGetters(['aboutLearningPlusIndividual', 'aboutLearningPlusBusiness']),
    height () {
      if (this.$vuetify.breakpoint.name === 'xs') {
        return 50;
      }
      return 80;
    },
  },
  props: {
    content: {
      type: Array,
    },
  },
};
</script>
