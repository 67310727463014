<template>
  <v-container fluid class="px-xl-16 pa-md-10">
    <v-row class="px-xl-16">
      <v-col cols="12" md="7">
        <h1 class="pp-subtitle-h1">
          {{ content.title }}
        </h1>
        <div class="pp-hr"></div>
        <h2 class="mt-6 text-h7">
          {{ content.subtitle }}
        </h2>

        <h3 class="text-h7 mt-4 font-weight-regular">
          {{ content.body1 }} <br class="mb-6" />  {{ content.body2 }}
        </h3>

        <AwardingBodyLogosDisplay />
      </v-col>

      <v-col cols="12" md="5" class="hidden-xs-only">
        <v-card
            class="rounded-xl ml-md-10"
            height="100%"
        >

          <div class="pp-title py-5">
            The new way of learning
          </div>

          <div class="pb-8">
            <div v-if="content.learnmore">
              <AboutUser :content="aboutLearningPlusBusiness"/>

              <div class="text-center">
                <div class="pp-hr pp-center"></div>
                <ContactUsButton />
                <div class="pp-hr pp-center"></div>
                <BrowseCourseButton />
              </div>
            </div>

            <div v-else>
              <AboutUser :content="aboutLearningPlusIndividual"/>

              <div class="text-center">
                <div class="pp-hr pp-center"></div>
                <BrowseCourseButton />
                <div class="pp-hr pp-center"></div>
              </div>
            </div>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import AwardingBodyLogosDisplay from "@/components/userPages/AwardingBodyLogosDisplay";
import AboutUser from "@/components/AboutLearningPlus";
import {mapGetters} from "vuex";
import BrowseCourseButton from "@/components/buttons/BrowseCourseButton";
import ContactUsButton from "@/components/buttons/ContactUsButton";

export default {
  name: "UserBody",
  components: {
    ContactUsButton, BrowseCourseButton, AboutUser, AwardingBodyLogosDisplay,
  },
  computed: {
    ...mapGetters(['aboutLearningPlusIndividual', 'aboutLearningPlusBusiness']),
  },
  props: {
    content: {
      type: Object,
    },
  },
};
</script>
