<template>
  <div>
    <title>Employer Page</title>
    <UserPageContent
        :content="userTypeContentEmployer"
    />
  </div>
</template>

<script>
import UserPageContent from "@/components/UserPageContent";
import {mapGetters} from "vuex";

export default {
  name: "Employers",
  metaInfo: {
    title: 'LearningPlus for Business',
    meta:[
      { description: 'Whether you are looking to enhance your employee\'s knowledge, wanting to develop your team\'s talent, are searching for an effective delivery system or are unsure where to start with your training needs, we can help.' },
      { image: 'https://www.learningplus.co.uk/images/customer/employer.jpg' },
    ],
  },
  components: {UserPageContent},
  computed: {
    ...mapGetters(['userTypeContentEmployer']),
  },
};
</script>
