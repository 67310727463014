<template>
  <div>
    <v-card :color="background">
      <v-container class="mt-14 pt-10 px-xl-16">
        <v-card
            elevation="5"
            class="rounded-xl mt-2"
            :color="banner"
        >
          <v-img
              :src="image"
              cover
              max-height="400"
          >
            <v-row class="pt-md-10 pt-5 pl-10">

              <v-col cols="10">
                <v-img
                    :src="logo"
                    contain
                    height="15"
                    class="hidden-md-and-up my-2"
                />
              </v-col>

              <v-col cols="4" class="pa-0 hidden-sm-and-down">
                <v-img
                    :src="logo"
                    contain
                    height="30"
                />
              </v-col>

              <v-img
                  :src="backgroundlogo"
                  max-height="230"
                  cover
                  class="mt-xl-3 mt-md-5"
              />

              <div class="btn pl-10 hidden-md-and-down">
                <v-row>
                  <div class="learner-btn mr-5">
                    <BrowseCourseButton />
                  </div>

                  <div v-if="learnmore" class="learner-btn">
                    <ContactUsButton />
                  </div>
                </v-row>
              </div>
            </v-row>

            <div class="hidden-lg-and-up text-center mb-5 ">
              <v-row>
                <div class="learner-btn-sm">
                  <BrowseCourseButton />
                </div>

                <div v-if="learnmore" class="learner-btn-sm">
                  <ContactUsButton />
                </div>
              </v-row>
            </div>
          </v-img>
        </v-card>
        <v-container class="px-xl-16 mt-2">
          <div v-if="learnmore">
            <UserSteps :content="userStepsBusiness" />
          </div>

          <div v-else>
            <UserSteps :content="userStepsIndividual" />
          </div>
        </v-container>
      </v-container>
    </v-card>
  </div>
</template>

<script>
import {mapGetters} from "vuex";
import UserSteps from "@/components/UserSteps";
import BrowseCourseButton from "@/components/buttons/BrowseCourseButton";
import ContactUsButton from "@/components/buttons/ContactUsButton";

export default {
  name: "UserBanner",
  components: {ContactUsButton, BrowseCourseButton, UserSteps},
  computed: {
    ...mapGetters(['userStepsIndividual', 'userStepsBusiness']),
  },
  props: {
    content: {
      type: Object,
    },
    background: {
      type: String,
    },
    banner: {
      type: String,
    },
    backgroundlogo: {
      type: String,
    },
    logo: {
      type: String,
    },
    image: {
      type: String,
    },
    to: {
      type: String,
    },
    contentType: {
      type: String,
    },
    learnmore: {
      type: String,
    },
  },
};
</script>

<style scoped>
.btn {
  position: absolute;
  bottom: 30px;
}

.learner-btn {
  background-color: white;
  padding: 2px;
  border-radius: 4rem;
}

.learner-btn-sm {
  border: white solid 2px;
  border-radius: 4rem;
  width: 250px;
  margin: auto;
}

@media screen and (min-width: 1900px) {
  .btn {
    bottom: 20px !important;
  }
}

@media screen and (max-width: 600px) {
  .learner-btn-sm {
    margin-top: 10px;
  }
}
</style>
